import React, { useEffect, useState } from 'react';
import { getToken, getUser, removeUserSession, setUserSession } from './Utils/Common';
import axios from 'axios';
import { RodeosIcon, salescontest } from './assets'
import './assets/materialize.min.css';
import './index.css';

function currencyFormat(num) {
  return 'Rp ' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function Dashboard(props) {
  const user = getUser();
  const [authLoading, setAuthLoading] = useState(true);
  const [batch1, setBatch1] = useState([]);

  const CekLogin = () => {
    const token = getToken();
    if (!token) {
      return;
    }

    const config = { headers: { Authorization: `Bearer ${token}`} };
    axios.get(`https://contest.seemitra.id/api/v1/users/key`, config).then(response => {
      setUserSession(response.data.token.token, response.data.username);
      setAuthLoading(false);
    }).catch(error => {
      removeUserSession();
      setAuthLoading(false);
    });
  }

  const loadBatch1 = () => {
    axios.get(`https://api.seemitra.co/api/v1/open_api_contest?api_key=7165a49ce6a0e940d1b8cff6dd2cc3ae`)
      .then(res => {
        const data = res.data.report;
        setBatch1(data);
        loadBatch2(data);
      }).catch(error => {
      })
  }

  const loadBatch2 = (data_old) => {
    axios.get("https://sys.ultiface.com/api/salescontest?api_key=60a1f0c2503b5")
      .then(res => {
        let data2 = res.data;
        let new_data = [...data_old, ...data2]

        new_data = new_data.sort(function (a, b) {
          return parseInt(a.total_price) - parseInt(b.total_price);
        });

        new_data = new_data.filter((d) => d.full_name !== 'Joko Santoso')
        new_data = new_data.filter((d) => d.full_name !== 'Indah Dwi Cahyani')
        new_data = new_data.filter((d) => d.full_name !== 'CS INTERNAL ULTIMEDIA')
        new_data = new_data.filter((d) => d.full_name !== 'M Rizki Ute')
        new_data = new_data.filter((d) => d.full_name !== 'Orderan Internal/Karyawan')
        new_data = new_data.filter((d) => d.full_name !== 'Seemitra')
        new_data = new_data.filter((d) => d.full_name !== 'Asri Sri Wahyuni')
        new_data = new_data.filter((d) => d.full_name !== 'Donny Tristianto')
        new_data = new_data.filter((d) => d.full_name !== 'Alwi Devira')
        new_data = new_data.filter((d) => d.full_name !== 'Abdul Mutholib')
        new_data = new_data.filter((d) => d.full_name !== 'Ayatullah Humaeni')
        new_data = new_data.filter((d) => d.full_name !== 'Riki Ramadhan')
        let data = new_data.reverse();


        setBatch1(data);
      }).catch(error => {
      })
  }

  // handle click event of logout button
  const handleLogout = () => {
    removeUserSession();
    props.history.push('/login');
  }

  useEffect(() => {
    CekLogin([]);
    loadBatch1('');
  }, []);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>
  }

  return (
    <div>
      <div className='App' style={{ backgroundColor: 'cyan'}}>
        <nav>
          <div className="nav-wrapper">
            <div className="container">
              <a href="https://rodeos.id" className="brand-logo">
                <img src={RodeosIcon} style={{ height: 60 }} />
              </a>
              <a href="#" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a>
              <ul className="right hide-on-med-and-down">
                <li>
                  <div style={{ color: 'white', marginRight: 10 }}>Welcome {user} </div>
                </li>
                <li>
                  <button class="btn waves-effect waves-light red" type="submit" name="action" onClick={handleLogout} value="Logout">Logout</button>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="container">
          <div className='col-md-12'>
            <center>
              <h1>RODEOS <br />SALES CONTEST 2022</h1>
              <p>**Event ini berlangsung dari Januari 2022 sampai April 2022</p>
              <a href="https://rodeos.id" className="brand-logo">
                <img src={salescontest} style={{ width: '100%', maxWidth: 400 }} />
              </a>
            </center>
            <br />
            <br />
            <>
              <div className="container">
                <div className='col m12'>
                  <center>
                    <table className='striped'>
                      <thead>
                        <tr>
                          <th data-field="id">
                            NO
                          </th>
                          <th data-field="name">
                            Nama
                          </th>
                          <th data-field="price">
                            Kota Asal
                          </th>
                          <th data-field="price">
                            Omset Dibayar
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          batch1 && batch1.length > 0 ? (
                            batch1.map((c, index) => {
                              return (
                                <tr style={{ backgroundColor: (index == 0 ? '#f8f880' : index <= 2 ? '#edeeee' : index <= 5 ? '#b2e6fc' : index <= 19 ? '#f8d066' : '') }}>
                                  <td>{index + 1}</td>
                                  <td><b>{c.full_name}</b></td>
                                  <td>{c.city}, {c.province}</td>
                                  <td>{currencyFormat(c.total_price)}</td>
                                </tr>
                              )
                            })
                          ) : null
                        }
                      </tbody>
                    </table>
                  </center>
                </div>
              </div>
            </>
            <br />

            <br />
            <br />
            <center>
              <p>Copyright @ Rodeos Indonesia 2022</p>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
